
import { defineComponent, ref } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Tab from '@/components/Tab.vue'
import Line from '@/components/Line.vue'
import TheSignals from '@/modules/membership/signal/components/TheSignals.vue'

export default defineComponent({
  name: 'TrendPro',
  components: { TheSignals, Line, PageWithHeader, Tab },
  setup () {
    const tabIndex = ref(0)

    const switchTab = (index: number) => {
      tabIndex.value = index
    }

    return {
      switchTab,
      tabIndex,
    }
  },
})
